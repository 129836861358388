var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14",
      "height": "20",
      "viewBox": "0 0 14 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.06917 11.4567L3.04836 19.6537L11.7262 8.00576L11.3412 6.64585L13.8962 3.13844L10.6405 4.17523L10.6555 4.22767L10.641 4.17773L9.48735 6.52749L0.10379 0.346191L7.06917 11.4567Z",
      "fill": _vm.fill
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }