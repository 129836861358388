<template>
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.06917 11.4567L3.04836 19.6537L11.7262 8.00576L11.3412 6.64585L13.8962 3.13844L10.6405 4.17523L10.6555 4.22767L10.641 4.17773L9.48735 6.52749L0.10379 0.346191L7.06917 11.4567Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
  export default {
    name: 'Sbis',
    props: { fill: String }
  }
</script>
