<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10873_111403)">
      <path
        d="M9.08529 0C5.74178 0.0570997 2.82638 1.5716 1.02577 4.2287L4.36958 6.7429C5.48408 5.0287 7.22758 4.1142 9.11369 4.1142C11.7258 4.1142 13.7366 5.79456 14.511 7.9858H6.85598L7.85628 12.0145H14.5218C13.7218 14.3124 11.5865 15.8858 9.11369 15.8858C7.14148 15.8858 5.36988 14.9145 4.36958 13.2861L0.99707 15.7716C2.90795 18.5085 5.87169 19.994 9.06505 20H9.09737C15.0922 19.9937 19.0019 15.4532 19.0019 9.9713C19.0019 4.2571 14.4007 0 9.08499 0L9.08529 0Z"
        :fill="fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_10873_111403">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
  export default {
    name: 'Evotor',
    props: { fill: String }
  }
</script>
