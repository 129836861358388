<template>
  <div
    :id="hash"
    v-b-tooltip.click.top
    class="logo-cont"
  >
    <sbis
      v-if="integration.type === 'sbis'"
      :fill="fill"
    />
    <crptech
      v-else-if="integration.type === 'crptech'"
      :fill="fill"
    />
    <egais
      v-else-if="integration.type === 'egais'"
      :fill="fill"
    />
    <evotor
      v-else-if="integration.type === 'evotor'"
      :fill="fill"
    />
    <mercury
      v-else-if="integration.type === 'mercury'"
      :fill="fill"
    />
    <xml
      v-else-if="integration.type === 'manual'"
      :fill="fill"
      :text_fill="text_fill"
    />
    <entersight
      v-else
      :fill="fill"
    />
    <b-tooltip
      ref="tooltip"
      :target="hash"
      custom-class="pixel-tooltip"
    >
      {{ integration.tooltip }}
    </b-tooltip>
  </div>
</template>

<script>
  import { EdoModel } from '@/views/operational-processes/models/edo.model'
  import Sbis from '@/assets/icons/Sbis.vue'
  import Entersight from '@/assets/icons/Entersight.vue'
  import Crptech from '@/assets/icons/Crptech.vue'
  import Egais from '@/assets/icons/Egais.vue'
  import Evotor from '@/assets/icons/Evotor.vue'
  import Mercury from '@/assets/icons/Mercury.vue'
  import Xml from '@/assets/icons/Xml.vue'

  export default {
    name: 'ExtSystemLogo',
    components: { Xml, Mercury, Evotor, Entersight, Egais, Crptech, Sbis },
    props: {
      ext_system: {
        type: EdoModel,
        default: () => {}
      },
      fill: { type: String, default: '#99DAFF' }
    },
    data() {
      return {
        integration: {
          type: '',
          tooltip: ''
        },
        hash: Date.now() + '' + Math.random()
      }
    },
    computed: {
      text_fill() {
        return this.fill === '#FFFFFF' ? '#00a3ff' : '#FFF'
      }
    },

    mounted() {
      switch (this.ext_system?.type) {
        case 'crptech':
          this.integration = {
            type: 'crptech',
            tooltip: 'Честный знак'
          }
          break
        case 'evotor':
          this.integration = {
            type: 'evotor',
            tooltip: 'Эвотор'
          }
          break
        case 'mercury':
          this.integration = {
            type: 'mercury',
            tooltip: 'Меркурий'
          }
          break
        case '':
          this.integration = {
            type: 'entersight',
            tooltip: 'Энтерсайт'
          }
          break
        case 'egais':
          this.integration = {
            type: 'egais',
            tooltip: 'ЕГАИС'
          }
          break
        case 'sbis':
          this.integration = {
            type: 'sbis',
            tooltip: 'СБИС'
          }
          break
        case 'manual':
          this.integration = {
            type: 'manual',
            tooltip: 'XML'
          }
          break
        default:
          this.integration = {
            type: 'entersight',
            tooltip: 'Энтерсайт'
          }
          break
      }
    }
  }
</script>

<style scoped>
  .logo-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
