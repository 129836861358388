var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19.7637 6.17188L17.9885 14.9461L13.303 18.7585H4.31055L5.03809 15.1207H14.2198L16.0386 6.17188H19.7637Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M13.0417 9.88246C13.0417 11.5122 11.703 12.8509 10.0733 12.8509C8.42902 12.8509 7.09033 11.5267 7.09033 9.88246C7.09033 8.2382 8.42902 6.91406 10.0733 6.91406C11.7175 6.91406 13.0417 8.25275 13.0417 9.88246Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M15.704 1.24023L14.9619 4.87797H5.78024L3.97592 13.8414H0.236328L2.0261 5.05259L6.6824 1.24023H15.704Z",
      "fill": _vm.fill
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }