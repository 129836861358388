var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.click.top",
      modifiers: {
        "click": true,
        "top": true
      }
    }],
    staticClass: "logo-cont",
    attrs: {
      "id": _vm.hash
    }
  }, [_vm.integration.type === 'sbis' ? _c('sbis', {
    attrs: {
      "fill": _vm.fill
    }
  }) : _vm.integration.type === 'crptech' ? _c('crptech', {
    attrs: {
      "fill": _vm.fill
    }
  }) : _vm.integration.type === 'egais' ? _c('egais', {
    attrs: {
      "fill": _vm.fill
    }
  }) : _vm.integration.type === 'evotor' ? _c('evotor', {
    attrs: {
      "fill": _vm.fill
    }
  }) : _vm.integration.type === 'mercury' ? _c('mercury', {
    attrs: {
      "fill": _vm.fill
    }
  }) : _vm.integration.type === 'manual' ? _c('xml', {
    attrs: {
      "fill": _vm.fill,
      "text_fill": _vm.text_fill
    }
  }) : _c('entersight', {
    attrs: {
      "fill": _vm.fill
    }
  }), _c('b-tooltip', {
    ref: "tooltip",
    attrs: {
      "target": _vm.hash,
      "custom-class": "pixel-tooltip"
    }
  }, [_vm._v(" " + _vm._s(_vm.integration.tooltip) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }