var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_10156_105258)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19.9998 6.59727C19.9998 6.66625 19.9989 6.73 19.9968 6.79899C19.3363 6.44227 18.6097 6.26149 17.889 6.26149C14.6605 6.26149 13.7679 9.15876 13.7679 10.3787C13.7679 11.4349 14.1555 12.5049 14.9253 13.3572C14.1628 13.6161 13.3632 13.7477 12.5573 13.7477L9.15188 13.7479L7.56633 15.3295C7.3279 15.5679 7.21786 15.8768 7.21786 16.1955C7.21786 16.7416 7.50083 16.8635 7.50083 17.642C7.50371 18.9606 6.42949 19.9996 5.14433 19.9996C4.53379 19.9996 3.9291 19.7631 3.47559 19.3043C3.02441 18.8567 2.81855 18.2766 2.81855 17.6926C2.81855 17.4993 2.84102 17.3058 2.88477 17.1164C2.69562 17.1598 2.50223 17.1821 2.30976 17.1821C0.663281 17.1821 0 15.7508 0 14.8579C0 13.552 1.06055 12.5008 2.35898 12.5008C3.1 12.5008 3.25625 12.7828 3.80273 12.7828C4.12144 12.7828 4.4293 12.673 4.66758 12.4346L6.25234 10.8538L6.25371 7.46446C6.25371 4.99961 7.33926 3.28477 8.72129 1.90195C10.0154 0.610547 11.7053 -0.0351562 13.3971 -0.0351562C15.0889 -0.0351562 16.7873 0.610547 18.0807 1.90195C19.3709 3.22227 19.9998 4.90977 19.9998 6.59727Z",
      "fill": _vm.fill
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_10156_105258"
    }
  }, [_c('rect', {
    attrs: {
      "width": "20",
      "height": "20",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }