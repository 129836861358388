var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.375 2.5V17.5C4.375 17.8438 4.65625 18.125 5 18.125H15C15.3438 18.125 15.625 17.8438 15.625 17.5V6.25H12.5C11.8086 6.25 11.25 5.69141 11.25 5V1.875H5C4.65625 1.875 4.375 2.15625 4.375 2.5ZM2.5 2.5C2.5 1.12109 3.62109 0 5 0H11.4648C12.1289 0 12.7617 0.261719 13.2305 0.730469L16.7695 4.26562C17.2383 4.73438 17.5 5.37109 17.5 6.03516V17.5C17.5 18.8789 16.3789 20 15 20H5C3.62109 20 2.5 18.8789 2.5 17.5V2.5Z",
      "fill": _vm.fill
    }
  }), _c('rect', {
    attrs: {
      "y": "7",
      "width": "20",
      "height": "9",
      "rx": "2",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M7.27 14H5.996L5.17 12.586L4.344 14H3.07L4.533 11.487L3.14 9.1H4.414L5.17 10.395L5.926 9.1H7.2L5.807 11.487L7.27 14ZM12.6608 9.1V14H11.5408V11.151L10.2738 13.23H10.1478L8.88082 11.151V14H7.76082V9.1H8.88082L10.2108 11.277L11.5408 9.1H12.6608ZM14.623 12.922H16.373V14H13.503V9.1H14.623V12.922Z",
      "fill": _vm.text_fill
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }