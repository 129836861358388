var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.4635 2.69576C16.7678 2.69726 17.0591 2.83427 17.2737 3.07681C17.4884 3.31936 17.6089 3.64769 17.6089 3.98995V7.2H20.0001V3.98995C20.0001 1.78587 18.4127 0 16.4635 0H13.6001V2.69576H16.4635Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M17.6089 16.4653C17.6089 16.7693 17.4882 17.0608 17.2734 17.2758C17.0586 17.4908 16.7673 17.6115 16.4635 17.6115H13.6001V19.9996H16.4635C17.4002 19.9983 18.2982 19.6257 18.961 18.9633C19.6238 18.3009 19.9974 17.4027 20.0001 16.4653V13.5996H17.6089V16.4653Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M2.39121 3.98995C2.39055 3.82 2.41965 3.65156 2.47685 3.49426C2.53405 3.33695 2.61824 3.19386 2.72459 3.07316C2.83095 2.95246 2.9574 2.85651 3.09672 2.79078C3.23604 2.72506 3.38551 2.69085 3.53658 2.69011H6.4V0H3.53658C2.5977 0.0029922 1.69818 0.424682 1.03523 1.17262C0.372276 1.92056 -3.7673e-06 2.93371 0 3.98995V7.2H2.39121V3.98995Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M3.53658 17.6115C3.23322 17.6102 2.94265 17.489 2.72814 17.2744C2.51363 17.0597 2.39253 16.7689 2.39121 16.4653V13.5996H0V16.4653C0.00265632 17.4027 0.376271 18.3009 1.03908 18.9633C1.70188 19.6257 2.5999 19.9983 3.53658 19.9996H6.4V17.6115H3.53658Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M8.55697 16.0004L3.2002 10.8727L4.96999 9.1735L8.55171 12.6021L15.0304 6.40039L16.8002 8.09955L8.55697 16.0004Z",
      "fill": _vm.fill
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }