var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_10156_105283)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19.8172 2.83467L17.1656 0.183105C16.9214 -0.0610352 16.5258 -0.0610352 16.2817 0.183105L15.3977 1.0667C15.1535 1.311 15.1535 1.70654 15.3977 1.95068L12.4043 4.94404C10.5828 4.17256 8.40429 4.52412 6.91992 6.0085L0.732422 12.196C-0.244141 13.1726 -0.244141 14.755 0.732422 15.7315L4.26797 19.2675C5.24453 20.244 6.82695 20.244 7.80351 19.2675L13.991 13.08C15.4754 11.5956 15.827 9.41748 15.0555 7.59561L18.0488 4.60225C18.293 4.84639 18.6934 4.84639 18.9375 4.60225L19.8215 3.71826C20.0594 3.47451 20.0594 3.07881 19.8172 2.83467ZM7.00471 16.5312L3.46916 12.9956L8.23479 8.22998L11.7703 11.7659L7.00471 16.5312Z",
      "fill": _vm.fill
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_10156_105283"
    }
  }, [_c('rect', {
    attrs: {
      "width": "20",
      "height": "20",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }